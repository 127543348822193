import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";

import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// import user side pages
import WithoutLogin from "./Login/WithoutLogin";
import UserHomePage from "./Home/UserHomePage/UserHomePage";

// admin side
import { QueryClientProvider, QueryClient } from "react-query";
import Dashboard from "./Admin/AdminHome/Dashboard";
import Signin from "./Admin/SignIn/Signin";
import UserStockRecommendation from "./Home/StockRecommendation/UserStockRecommendation";
import OrderBookDetailsPage from "./Home/OrderBookDetails/OrderBookDetailsPage";
import TradeHistoryHomePage from "./Home/TradeHistory/TradeHistoryHomepage";
import RecomenationStatusHomepage from "./Admin/RecommendationStatus/RecomenationStatusHomepage";
import SubscriptionPage from "./Home/Subscriptions/SubscriptionPage";
import Settings from "./Home/Settings";
import IgnoreTradesPage from "./Home/IgnoreTrades/IgnoreTradesPage";
import ContactAdvisorPage from "./Home/ContactAdvisor/ContactAdvisorPage";
import StrategyListHomePage from "./Admin/Strategies/StrategyListHomePage";
import StraegyDetailsPage from "./Admin/Strategies/StrategyDetailsPage";
import AllStrategyListHomePage from "./Home/Strategy/AllStrategyListHomePage";
import SingleStrategyDetailsPage from "./Home/Strategy/SingleStrategyDetailsPage";
import { useSelector } from "react-redux";
import ClientListNewPage from "./Admin/ClientListNew/ClientListNewPage";
import AdminSettingsPage from "./Admin/AdminSettings/AdminSettingsPage";
import ClientListDetailsNewPage from "./Admin/ClientListNew/ClientListNewDetailsPage";
import TermsAndConditionsByAdvisor from "./Admin/TermsAndConditions/TermsAndConditionsByAdvisor";
import TermsAndConditionsByAlpha from "./Admin/TermsAndConditions/TermsAndConditionsByAlpha";
import DisclaimerPage from "./Admin/TermsAndConditions/DisclaimerPage";

const queryClient = new QueryClient();

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  const [user] = useAuthState(auth);

  const isAuth = useSelector(isAuthenticated);
  return (
    <div className="select-none">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path="/"
            element={user ? <UserHomePage /> : <WithoutLogin />}
          />
          <Route path="/new" element={user ? <Settings /> : <WithoutLogin />} />
          <Route
            path="/stock-recommendation"
            element={user ? <UserStockRecommendation /> : <WithoutLogin />}
          />
          <Route
            path="/order-details"
            element={user ? <OrderBookDetailsPage /> : <WithoutLogin />}
          />
          <Route
            path="/trade-history"
            element={user ? <TradeHistoryHomePage /> : <WithoutLogin />}
          />

          <Route
            path="/subscriptions"
            element={user ? <SubscriptionPage /> : <WithoutLogin />}
          />
          <Route
            path="/contact-advisor"
            element={user ? <ContactAdvisorPage /> : <WithoutLogin />}
          />
          <Route
            path="/ignore-trades"
            element={user ? <IgnoreTradesPage /> : <WithoutLogin />}
          />
          <Route
            path="/strategy"
            element={user ? <AllStrategyListHomePage /> : <WithoutLogin />}
          />
          <Route
            path="/strategy/:fileName"
            element={user ? <SingleStrategyDetailsPage /> : <WithoutLogin />}
          />

          <Route path="/admin" element={isAuth ? <Dashboard /> : <Signin />} />

          <Route
            path="/admin/client-list"
            element={isAuth ? <ClientListNewPage /> : <Signin />}
          />
          <Route
            path="/admin/client-list/:groupName"
            element={isAuth ? <ClientListDetailsNewPage /> : <Signin />}
          />

          <Route
            path="/admin/send-recommendation-status"
            element={isAuth ? <RecomenationStatusHomepage /> : <Signin />}
          />

          <Route
            path="/admin/admin-strategy"
            element={isAuth ? <StrategyListHomePage /> : <Signin />}
          />

          <Route
            path="/admin/admin-strategy/:fileName"
            element={isAuth ? <StraegyDetailsPage /> : <Signin />}
          />

          <Route
            path="/admin/admin-settings"
            element={isAuth ? <AdminSettingsPage /> : <Signin />}
          />
          <Route
            path="/terms-and-conditions-by-advisor"
            element={<TermsAndConditionsByAdvisor />}
          />
          <Route
            path="/terms-and-conditions-by-finatwork"
            element={<TermsAndConditionsByAlpha />}
          />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Alpha100 from "../../assests/alpha-100.png";
import VolatilityIcon from "../../assests/volatility.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";
import { GitForkIcon } from "lucide-react";
const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px]",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const StrategyList = () => {
  const navigate = useNavigate();
  const [allStrategy, setAllStrategy] = useState([]);

  const getAllStrategy = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/model-portfolio/portfolios/FinatWork`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("res", response);

        setAllStrategy(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllStrategy();
  }, []);

  const handleCardClick = (modelName) => {
    navigate(`/strategy/${modelName.toLowerCase().replace(/ /g, "_")}`);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className=" max-h-[calc(100vh-60px)] md:max-h-[calc(100vh-100px)] lg:min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <div className="px-4  md:px-10 flex flex-row  justify-between border-b border-[#000000]/20  h-[50px] lg:h-[60px] font-poppins">
        <div className="flex flex-row space-x-6 lg:space-x-2">
          <div
            className={`
              ${style.selected} flex items-center justify-center`}
          >
            All Strategies
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-[calc(100vh-120px)] sm:h-[calc(100vh-120px)]  lg:h-[calc(100vh-80px)] overflow-scroll custom-scroll md:pb-6">
          {allStrategy.length !== 0 ? (
            <>
              {allStrategy.map((ele, i) => {
                return (
                  <div
                    className="w-full  flex flex-col gap-x-4 space-y-3 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between border-b-[1px] lg:h-[100px] border-[#000000]/10 xl:px-10 py-2 md:py-5"
                    key={i}
                    onClick={() => handleCardClick(ele.model_name)}
                  >
                    <div className="flex items-start gap-x-3 px-3 lg:px-0 lg:flex-row lg:space-x-8">
                      {ele?.image ? (
                        <img
                          src={`${server.server.baseUrl}${ele.image}`}
                          alt={ele.model_name}
                          className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
                        />
                      ) : (
                        <img
                          src={Alpha100}
                          className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
                        />
                      )}

                      <div className="flex justify-center flex-col">
                        <div className="flex items-center gap-x-3">
                          <h3 className="text-[18px] font-sans font-bold text-[#000000] cursor-pointer">
                            {ele.model_name}
                          </h3>
                          <div className="text-xs font-poppins font-medium text-[#0E846D] bg-[#D5FFE7] rounded-[3px] px-2 py-1">
                            New
                          </div>
                        </div>

                        <p className="w-full lg:w-[300px] text-[12px] lg:text-[14px] font-poppins font-medium text-[#000000]/50">
                          {ele.overView.split(" ").slice(0, 10).join(" ")}
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-4 gap-x-0 lg:gap-x-12 ">
                      <div className="flex flex-col items-center justify-center lg:px-4">
                        <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
                          CAGR
                        </div>
                        <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
                          +75.2%
                        </p>
                      </div>

                      <div className="flex flex-col items-center justify-center lg:px-4">
                        <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
                          2Y Returns
                        </div>
                        <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
                          +775.2%
                        </p>
                      </div>

                      <div className="flex flex-col items-center justify-center lg:px-4">
                        <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
                          Volatility
                        </div>

                        <div className="flex space-x-2">
                          <div className="flex items-center justify-center">
                            <img
                              src={VolatilityIcon}
                              className="h-[20px] w-[20px]"
                            />
                          </div>
                          <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold ">
                            Low
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col items-center justify-center lg:px-4">
                        <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
                          Score
                        </div>
                        <p className="flex items-center justify-center text-[10px] lg:text-[12px] rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[20px] w-[20px] lg:h-[24px] lg:w-[24px] ">
                          10
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-6 w-full relative  bg-[#f9f9f9] h-full">
              <div className="flex flex-row items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                <GitForkIcon size={50} className="w-[60px] lg:w-[80px]" />
              </div>
              <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                  No Strategies Available Now
                </div>
                <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                  When your advisor create any strategy it appers here
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StrategyList;

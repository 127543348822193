import server from "../../utils/serverConfig";
import axios from "axios";

const adminDetailsString = localStorage.getItem("userDetails");
const adminDetails = adminDetailsString ? JSON.parse(adminDetailsString) : null;
const adminEmail = adminDetails ? adminDetails.email : null;

export default async function fetchAdminData() {
  const advisorName = "FinatWork";
  const url = `${server.server.baseUrl}api/terms-conditions/${advisorName}`;

  try {
    // Send a GET request to fetch data
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    // Parse the JSON response
    const data = await response.json();
    console.log("data", data);

    return data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return null; // Return null in case of an error
  }
}
